<template>
  <div ref="container"></div>
</template>

<script>
export default {
  mounted() {
    if (!window.opener) {
      window.close();
    } else {
      this.pay();
    }
  },

  methods: {
    pay() {
      const onReceivePreviewData = (event) => {
        const source = "getAlipayFormData";
        let json = null;
        try {
          json = JSON.parse(event.data);
        } catch (e) {}
        if (!json || json.source !== source || !json.data) return;

        const div = document.createElement("div");
        this.$refs.container.appendChild(div);
        div.innerHTML = json.data;
        document.forms["punchout_form"].setAttribute("target", "_self");
        document.forms["punchout_form"].submit();
        div.remove();
      }

      if (window.opener) {
        window.removeEventListener("message", onReceivePreviewData);
        window.addEventListener("message", onReceivePreviewData);
        const data = { source: "getAlipayForm" };
        window.opener.postMessage(JSON.stringify(data), "*");
      }
    },
  },
};
</script>
